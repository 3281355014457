import React, { useEffect, useState } from "react";


import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.scss";
import NewOaTrendySignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import queryString from "query-string";
import * as localLang from "../../../i18n/i18n";
import { useParams } from 'react-router-dom';
import {useLocation } from 'react-router-dom';


const randomBg = bgSliders.getRandomBg();
function NewOaTrendySignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [transactionidentify,setTransactionidentify] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [transactionid,setTransactionid] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");
  const [agree, setagree] = useState(true);
  const [showterms, setshowterms] = useState(true);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [showSub, setshowSub] = useState(false);

   const [phonenumber , setphonenumber] = useState("");
   const [sub_id , setsub_id] = useState("");

  const history = useHistory();
  const [alert, setAlert] = useState(false);


  const plans = [
    {
      plan: "d",
      name: "Daily"
    },
    {
      plan: "w",
      name: "Weekly"
    } 
  ];
  const [hasRedirected, setHasRedirected] = useState(false);
  const { msisdn } = useParams();

  useEffect(() => {
    //yousry lang
   //console.log("yousry lang");
    MCPShield();
   
  

    const params = queryString.parse(window.location.search);
    if (params.lang) {
     //console.log("lang",params.lang);
      localStorage.setItem("i18nextLng", params.lang);
      if(localStorage.getItem("i18nextLng") != params.lang){
        localStorage.setItem("i18nextLng", params.lang);
        window.location.reload();
      }
      //window.location.reload();
      //localLang.checklocalLanguage();
    }


    if (params.msisdn == undefined) {
        setshowSub(false);
        getRedirectURL(params);
   
     }
     else{
     
     var phone = params.msisdn;
    
     if(phone !== null &&  phone !== ""){
       const fetchDataWithMsidn = async () => {
        try {
          const response = await axios.get(`http://api.playit.mobi/api/v2/trendy-algeria/gethemsisdn?decry_msisdn=${phone}`);
           const phone_number= response.data.msisdn;
           
           const subId =response.data.sub_id;
          setphonenumber(phone_number);
          setsub_id(subId);
          setshowSub(true)
           //console.log(phonenumber);
          if(phone_number){
            setshowSub(true)
          }
          
           
        } catch (error) {
          console.error('Error  msidn:', error);
        }
      };

      fetchDataWithMsidn();
     }
     
     }
   

    const urlsearch = window.location.search;
    if (urlsearch !== "") {
     //console.log("tracker")
      setAgencyData(urlsearch);
    }
  }, []);

 
  async function getRedirectURL(params){
    var url = "http://api.playit.mobi/api/v2/trendy-algeria/getheredirecturl";

    if(params.trafficsource){
      url = "http://api.playit.mobi/api/v2/trendy-algeria/getheredirecturl?trafficsource="+params.trafficsource+"&parameter="+params.parameter;

    }
    await axios.get(url)
    .then(response => {
    
       const url = response.data.redirect_url;
       if(url !=="" && url != null){
         
           window.location.href=url;
       }
      
      
    })
    .catch(error=> {
      console.log(error);
      
    })
    
  }
  
    
  async function MCPShield(){
    //e.preventDefault();
   //console.log("header Subscribe");
    var url = `https://api.playit.mobi/api/v2/trendy-algeria/get_script?element=signupbut`;
    //setshowLoading(true);
    axios.get(url)
      .then(res => {
       console.log(res);
       console.log(res.data.status);
        if (res.data.status == true) {
          const addScript = document.createElement('script');
          // addScript.id = "addScript";
          addScript.innerHTML = res.data.script;
          document.head.appendChild(addScript);
          setTransactionidentify(res.data.transactionId)
          setTransactionid(res.data.ti)
          setshowLoading(false);
          var event = new Event('DCBProtectRun');
          document.dispatchEvent(event);
        }
        else {
          setErrorMesg(true);
          setErrorMesgSubscribe(res.data.message)
          // setshowagin(true)
        }

        setshowLoading(false);

      })
      .catch(function (error) {
        // setErrorMesg(true)
      })
      .finally(function () {
       //console.log('complete');
      });
    }

  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem('tracker');
      if(localStorage.getItem(tracker_value) != '1'){
        window.gtag('event', 'conversion', {
          'send_to': 'AW-619421651/C_ScCIHe_qcZENO_rqcC'
      });
      localStorage.setItem(tracker_value,'1')
    }
    return false;
  }


  

  function manipulateMsisdn(msisdn) {
    msisdn = msisdn.replace('+', '');
    if (startsWith(msisdn, '0')) {
        msisdn = msisdn.substring(1);
    } else if (startsWith(msisdn, '00')) {
        msisdn = msisdn.substring(2);
    }
    if (!startsWith(msisdn, '213')) {
        msisdn = '213' + msisdn;
    }
    return msisdn.trim();
}


function startsWith(str, prefix) {
    return str.indexOf(prefix) === 0;
}

    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'zFGLFvkj8L';
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
       //console.log(datag)  
      }
      await saveTracker(datag);
  
    }

  const Subscribe = async e => {
   console.log(e.target.phone_number.value);
    setActiveData(e.target.phone_number.value);
    const phone_number_val = e.target.phone_number.value;
   //console.log(activeData);
    e.preventDefault();
    
    if (phone_number_val) {
      var phon_n = phone_number_val.toString();
      console.log(manipulateMsisdn(phon_n));
      const url = `http://api.playit.mobi/api/v2/trendy-algeria/send-otp?msisdn=${phon_n}&plan=w&transaction_identify=${transactionidentify}`
      setshowLoading(true);
      await axios.get(url)
        .then(res => {
          if (res.data.status === true) {
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid)
            }
            setShowverif(true)
          }
          else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);

            }
            else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);


            }
           // setShowverif(true)


          }
          setshowLoading(false);

        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
  };


  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      setshowLoading(true);
      
      axios.get(`http://api.playit.mobi/api/v2/trendy-algeria/verify?msisdn=${phon_n}&pincode=${verifyCode}&plan=w&ti=${transactionid}`)
        .then(res => {
         //console.log(res);
          if (res.data.status == true) {
            setVerfiErrorMesg(false);
            tracker(manipulateMsisdn(phon_n));
            gtag_report_conversion();
            if (res.data.data.user_id) {
              
                history.push("/catch-trendy?msisdn="+res.data.data.username);
                //history.push("/");
              
                //setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              //history.push("/catch-Libyana?msisdn="+res.data.data.username);
            }else{
              if (res.data.message != null) {
                seterrorresverify(res.data.msg);;
                setVerfiErrorMesg(true);
      
              }
            }
           //  window.location.href = "/";
           //history.push("/catch-Libyana")
          } else {
            console.log("res.data.message ",res.data.message);
            if (res.data.message != null) {
              seterrorresverify(res.data.message);;
              setVerfiErrorMesg(true);

            }
            else {
              // setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);


            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setshowLoading(false);

        })
        .catch(function (error) {
         //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);

        })
        .finally(function () {

         //console.log('complete');
        });
    }
  };


  const subscribeHe = async e => {
    e.preventDefault();

      var phon_n = phonenumber;
     
      setshowLoading(true);
      if(phon_n != null && phon_n !== "" && phon_n !== undefined){
         axios.get(`http://api.playit.mobi/api/v2/trendy-algeria/subscribe_he?msisdn=${phon_n}&sub_id=${sub_id}&plan=w&isHeader=yes&ti=${transactionid}`)
        .then(res => {
         //console.log(res);
         setshowLoading(false);
          if (res.data.status == true) {
            setVerfiErrorMesg(false);
            tracker(manipulateMsisdn(phon_n));
            gtag_report_conversion();
            history.push("/catch-trendy?msisdn="+manipulateMsisdn(phon_n));
                
            if (res.data.data.user_id) {
              
                history.push("/catch-trendy?msisdn="+res.data.data.username);
                //history.push("/");
              
                //setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              //history.push("/catch-Libyana?msisdn="+res.data.data.username);
            }else{
              if (res.data.message != null) {
                seterrorresverify(res.data.message);
                setVerfiErrorMesg(true);      
              }
            }
           //  window.location.href = "/";
           //history.push("/catch-Libyana")
          } else {
            if (res.data.message != null) {
              if(res.data.code && res.data.code ==  "1"){
                seterrorresverify(t('register.SUBSCRIBEERROR'));;
              }else{
                seterrorresverify(t('register.SUBSCRIBE_ERROR'));;
              }
              setVerfiErrorMesg(true);

            }
            else {
              // setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);


            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setshowLoading(false);

        })
        .catch(function (error) {
         //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);

        })
        .finally(function () {

         //console.log('complete');
        });

      }
      else{
        const paramsurl = queryString.parse(window.location.search);
        getRedirectURL(paramsurl);
      }
     
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });

  const handlclick = e => {
    e.preventDefault();
//    setshowbutton(true);
    setshowterms(false);
  }



  return (
    <NewOaTrendySignupPageDisplay className="">
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal">

          <div className='header header_gamezone'>
            <Link to="/">
              <div className='logo logo gamezoneLogo' />
            </Link>
          </div>
          {!showverif ?
            <div className="links-Section links-Sectionjawwalgamezorid">

              {/* <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3> */}
              <div className="play"></div>

      <form name="formsubmit" onSubmit={(e) => {  e.preventDefault(); subscribeHe(e); }}>
 {showSub ? <>
    <input
                type="text"
                className="input gamezoneInput gamezonekeyph"
                value="+213"
                disabled={true}
              />
<input
  type="text"
  className="input gamezoneInput gamezonenumph"
  readOnly
  value={phonenumber}
  name="phone_number"
  disabled={!phonenumber }
/>
 
 </>
   : ""}
             

              <br/><br/><br/>
              <button
                id="signupbut"
                className="btn"
                disabled={agree == true ? "" : "disabled"}
                // style={{ backgroundColor: !agree ? "" : "#8ce6e6" }} 
                //disabled={activeData ? "" : "disabled"}
                type="submit"
                
              >
                {t("subscribe")}
              </button>
      </form>

      <div className="ncellTos jawwalgamezoridncellTos mondiancellTos">
      {showterms ?
                  <h4>
                    <input type="checkbox" style={{ margin: '4px 9px 0' }} onChange={(e) => { setagree(!agree) }} checked={agree} />
                    <Trans>mondia_conditions.accept_condition</Trans></h4>
                  : ""}
                
       </div>

              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>


              <div className="footer jawwalgamezoridFooter zainiqFooter">
                {!showverif ? "" : null}
                <div className="ncellTos jawwalgamezoridncellTos">
                  {/* <h3><Trans>terms_and_conditions</Trans></h3> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>trendy-condtion_tandc1</Trans></p>
                  {/* <p><Trans>zainiq-condtion-gamezone_tandc2</Trans></p> */}
                  {/* <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>trendy-condtion_tandc3</Trans></p> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>trendy-condtion_tandc4</Trans></p>
                  {/* <p style={{lineHeight:"2px"}}><Trans>libyana-condtion_tandc5</Trans></p> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>trendy-condtion_tandc6</Trans></p>
                  {/* <p> <Trans>JAWWALGameSUBSCRIBE_TAX</Trans> </p>
                  <p> <Trans>JAWWALGamSUBSCRIBEUNSUBSCRIBE</Trans> </p> */}
                </div>
              </div>
            </div>

            : null}

          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeJawwalGameszorid</Trans></p>
              <input
              
                type="text"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}
              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

      {/* <div className='operatorSignup'>
        <div className="formsLayout">

          <div className='header'>
            <Link to="/">
              <div className='logo' />
            </Link>
          </div>

          {!showverif ?

            <div className="center">
              <form>
                <div>
                   <select

                    className="select"
                    defaultValue={signupPlan}
                    onChange={e => setSignupPlan(e.target.value)}
                  >

                    <Trans>{plansOptions}</Trans>
                  </select>
                

                  <input
                    type="number"
                    className="input"
                    placeholder={t("Enter_your_phone_number")}
                    value={activeData}
                    disabled={checkPhone == true}
                    onChange={e => setActiveData(e.target.value)}
                  />
                  <br></br>
                  <br></br> <br></br>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      Subscribe(e);
                    }} >
                    {t("Subscribe")}
                  </button>

                </div>
              </form>
            </div>

            : null}




          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeSub</Trans></p>
              <input
                type="number"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={e => setVerifyCode(e.target.value)}
              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }


          <div className="footer">
           
            <div className="ncellTos">

            </div>
          </div>

        </div>
      </div> */}

    </NewOaTrendySignupPageDisplay>
  );
}
export default withTranslation()(NewOaTrendySignupPage)
