import React, { Component } from "react";
import img from "../../assets/images/complete.svg";
import bgSliders from "../../helpers/bgSliders";
import h from "../../helpers/helpers";
import "./SubscribeCompletePage.scss";
import SubscribeCompletePageDisplay from "./SubscribeCompletePage.styled";
import { Link } from 'react-router-dom';
import { saveTracker } from "../../helpers/agencyTracker";
import queryString from "query-string";

  // save tracker
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = 'ZijMZyHHxh';


      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: op_id,
        phoneNum: GMSISDN
      };
     //console.log(datag)

    }
    await saveTracker(datag);

  }

const randomBg = bgSliders.getRandomBg();
export default class SubscribeCompletePage extends Component {
  async componentDidMount() 
  {
    const data = queryString.parse(window.location.search)
    if (data.clickid) {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = '6WPc8GR1LF';
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: data.clickid,
          datasync: 1
        };

      }

      saveTracker(datag);

    }
    await h.wait(5000);
    return this.props.history.push("/");
  }
  render() {
    return (
      <SubscribeCompletePageDisplay bgImg={randomBg}>
        <div className="formPage">
          <div className="formsLayout">
            <div className="header">
            <Link to="/">
              <div className="logo" />
              </Link>
            </div>
            <div className="center">
              <img src={img} alt="Complete" />
            </div>

            <div className="footer">
              <div>
                <p>subscription complete</p>
              </div>{" "}
              <div>If you're not redirected within 5 seconds </div>
              <div>
                <a href="/">Click Here</a>
              </div>
            </div>
          </div>
        </div>
      </SubscribeCompletePageDisplay>
    );
  }
}
