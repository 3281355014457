import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./OmentelLoginPage.scss";
import Modal from 'react-awesome-modal';
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import OmentelLoginPageDisplay from "./OmentelLoginPage.styled";
import { useHistory } from "react-router";

import queryString from "query-string";

const randomBg = bgSliders.getRandomBg();
function OmentelLoginPage({ t }) {


  const [phone, setphone] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [errsubMsg, seterrsubMsg] = useState("");
  const [errors, seterrors] = useState(false);
  const [errsub, seterrsub] = useState(false);
  const [verify_errors, setverify_errors] = useState(false);
  const [show, setshow] = useState(true);
  const [loading, setloading] = useState(false);
  const [validcode, setvalidcode] = useState(false);

  const [phonePlaceholder, setphonePlaceholder] = useState("enter_phone_number");
  const history = useHistory();



  useEffect(() => {

  const query = queryString.parse(window.location.search);
  if(query.code)
  {
    setvalidcode(true)
   login(query.operator,query.plan_id,query.code);
 }
   check();
  } , [])



  async function check() {
    if (await checkAuth()) {
      history.push("/");
    }
  }


  async function login( operator,plan,code) {
    if (code) {
      var url = `http://www.api.playit.mobi/api/v2/mondia-oman/get-sub-details?operator=${operator}&plan_id=${plan}&code=${code}`;
      axios.post(url , {})
        .then(res => {
          if (res.data.status == "success") {
            if (res.data.data.username && res.data.data.password) {
            

              const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password);
              if (hasAccess) { 
                
                 // setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.username, res.data.data.photo, res.data.data.operator_id, res.data.data.user_email, res.data.data.msisdn);
                 
                history.push(`/`);
               
                
              }
            }
          }
          else {
            seterrorMsg(res.data.data)
            seterrors(true);
           
          }
          setloading(false)

        })
        .catch(function (error) {
         console.log(error)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
    
  }

  
  async function redirectUrl(e) {
  
    e.preventDefault();
    if (!isValid(phone)) {
      seterrors(true);
      seterrorMsg("Empty_Phone_Number")
      return;
    }
      var url = ` http://www.api.playit.mobi/api/v2/mondia-oman/get-login-url?operator=ooredoo&msisdn=${phone}`;
      axios
        .post(url , {})
        .then((res) => {
         
          if (res.data.status == "success") {
            window.location.href = res.data.data.url;
          } else {
            seterrors(true);
            seterrorMsg("Some thing is wrong")
      
          }

        })
        .catch(function (error) {
          seterrorMsg(true);
        })
        .finally(function () {
         //console.log("complete");
        });

   
  }

  async function checkAuth() {

    const auth = await client.auth();
    return auth;
  }

 


  async function confirmLogin(username,password) {

    const hasAccess = await client.login(username,password);
    return hasAccess;
  }


  function onInputChange(value) {
    setphone(value);

  }

 function isValid(phone) {
    return phone ? true : false;
  }
  
  return (
    <>
      {loading ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : ""}
      <OmentelLoginPageDisplay className="" bgImg={randomBg}>

        <div className="formPage">
          <form
         
          >
            <div className="formsLayout" style={{ minHeight: '45vh' }}>
              <div className="header">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
            {/*   <div className="smsText" style={show ? { display: 'none' } : {}}>
                <p >يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
              </div> */}
              <div className="center" style={{ width: '90%' }}>

                <div className="form">
                  <div className="iconInput">
                    <i className="icon phone" />
                    <input
                      type="number"
                      className="input"
                      autoComplete="phone"
                      placeholder={t("enter_phone_number")}
                      onChange={e =>
                        onInputChange(e.target.value)
                      }
                    />
                  </div>

                </div>
              </div>

            </div>
          </form>


          <div className="footer">
            <div className="normBtn">
              <button className="btn" onClick={e => redirectUrl(e)} disabled={validcode ? "disabled" : ""}>
                <Trans>sign_in</Trans>
              </button>
            </div>



            <p>
              <Trans>login.don’t_have_account</Trans>{" "}
              <Link to="signup">
                <Trans>sign_up</Trans>
              </Link>
            </p>
          </div>
          {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}
          {errsub ? <FloatingAlert type="error"><Trans>{errsubMsg}</Trans></FloatingAlert> : ""}


        </div>
      </OmentelLoginPageDisplay>
    </>
  );

}




/* End Update State Use REDUX */
export default withTranslation()(OmentelLoginPage)



