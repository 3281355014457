import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.scss";
import TrueMoveSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import queryString from "query-string";
import * as localLang from "../../../i18n/i18n";
// import TagManager from 'react-gtm-module'


const randomBg = bgSliders.getRandomBg();
function TrueMoveSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [transactionidentify,setTransactionidentify] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);
   var shortcode="4221406";
   var subpack="04";
   var backurl="ais.playit.mobi/complete";
   var cancelurl="ais.playit.mobi/unsubscribe";


  const history = useHistory();
  
  const [alert, setAlert] = useState(false);

  const params = queryString.parse(window.location.search);

 
 


  useEffect(() => {
   
    if (params.lang) {
     
      localStorage.setItem("i18nextLng", params.lang);
      
    }
     const urlsearch = window.location.search;
    if (urlsearch !== "") {
     
      setAgencyData(urlsearch);
    }
 //   headerSignup(params);
   
   
  }, []);


      // save tracker
      const tracker = async (GMSISDN) => {
        var datag = {};
        if (localStorage.getItem('tracker')) {
          var op_id = '6WPc8GR1LF';
      
          datag = {
            key: 'parameter',
            company: localStorage.getItem("trackerCompany"),
            value: localStorage.getItem('tracker'),
            identifierKey: 'id',
            identifierValue: op_id,
            phoneNum: GMSISDN
          };
         //console.log(datag)
    
        }
        await saveTracker(datag);
    
      }
  
  async function headerSignup(params){
    setshowLoading(true);
    var url = "http://api.playit.mobi/api/v2/digitallabs-thailand/getheredirecturl";

    if(params.trafficsource){
      url = "http://api.playit.mobi/api/v2/digitallabs-thailand/getheredirecturl?trafficsource="+params.trafficsource+"&parameter="+params.parameter;

    }
   
   
    axios.get(url)
      .then(res => {
       //console.log(res);
       
        if (res.data.status == true) {
          
          const addScript = document.createElement('script');
          const addScriptheader= document.createElement('script');
          const shortcode = "4221406";
          const subpack = "04";
          const cancelurl = "http://ais.playit.mobi/unsubscribe";
          const backurl = "http://ais.playit.mobi/complete?clickid="+res.data.click_id;
          
          addScriptheader.textContent = `
                    var shortcode = "${shortcode}";
                    var subpack = "${subpack}";
                    var cancelurl = "${cancelurl}";
                    var backurl = "${backurl}";
                `;
       // console.log(addScriptheader)
          tracker(res.data.click_id);
          
          addScript.src = res.data.redirect_url;
          //console.log(addScript);
          addScript.type = "text/javascript";
          addScript.defer = true;
          addScript.charset = "utf-8";
           document.head.appendChild(addScriptheader);
          document.body.appendChild(addScript);
         
          setshowLoading(false);

        
        }

        else {
          setErrorMesg(true);
          setErrorMesgSubscribe(res.data.message)
          
        }

        setshowLoading(false);


      })
      .catch(function (error) {
        // setErrorMesg(true)
      })
      .finally(function () {
       //console.log('complete');
      });
  }

   


  



  return (
    <TrueMoveSignupPageDisplay className="">
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className="operatorSignup">
        <div className="formsLayout formsLayoutjawal">
          <div className="header header_gamezone ">
            <div className="logo-header">
              <Link to="/">
                <div className="logo logo gamezoneLogo" />
              </Link>
            </div>
            <div className="btn-header">
              <Link className="button-header" onClick={() => history.goBack()} >
                X
              </Link>
            </div>
          </div>

          <div className="links-Section links-Sectionjawwalgamezorid">
            <div className="play"></div>
            <div>
              <h1 className="text-xl text-center mb-0 col-md-6 thai-text fw-bold">
                Playit
              </h1>
            </div>

            <div>
              <p>
                <span className="thai-text"> 5 </span>บาท/ข้อความ และจัดส่ง
                <span className="thai-text">2 </span>ข้อความ/วัน
              </p>
              <p>(ไม่รวมภาษีมูลค่าเพิ่ม 7% และค่าบริการอินเทอร์เน็ต)</p>
            </div>
            <br></br>
            <div >
              <Link
                  
                  to={""}
                  className="btn-thai "
                >
                  สมัครบริการ
                </Link> 
            </div>

            <br></br>

            <div>
              <p className="mb-0">
                สมัครบริการพิมพ์ <span className="thai-text">G4</span> ส่งไปที่
                4221406 หรือกด *4221406<span className="thai-text">04</span>{" "}
                โทรออก
              </p>

              <p className="mb-0">
                ยกเลิกบริการพิมพ์ STOP <span className="thai-text">G4</span>{" "}
                ส่งไปที่ 4221406 หรือกด *4221406
                <span className="thai-text">95</span> โทรออก
              </p>

              <p className="mb-0">สอบถามข้อมูลเพิ่มเติมโทร 02-119-2636</p>
              <p className="mb-0">จันทร์ ศุกร์ เวลา 09:00 – 17:00 น.</p>
            </div>
          </div>
        </div>
      </div>
    </TrueMoveSignupPageDisplay>
  );
}
export default withTranslation()(TrueMoveSignupPage)
