import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import queryString from "query-string";
import { setAgencyData, callTrackerCompany, saveTracker } from "../../helpers/agencyTracker";

import "./Thank-you-page.scss";
const urlksa= window.location.hostname.includes("ksa");
const urlGamesroid = window.location.hostname.includes("gamezroid");


//const urlksa= window.location.hostname.includes("local");
//const urlGamesroid = window.location.hostname.includes("local");





class KsaThankYouPage extends Component {
  constructor() {
    super();

  }
  async componentDidMount() {
    const data = queryString.parse(window.location.search);
    let routing = localStorage.getItem('routing');
    // if(urlksa && data.trafficsource == 'GrandMarketing')
    // {
    //   window.gtag('event', 'conversion', {'send_to': 'AW-593016567/7XFmCM-gyPEBEPft4poC'});

    // }
    // //http://ksa.playit.mobi/thank-ksa?trafficsource=
    // if(urlksa && data.trafficsource != 'GrandMarketing'){
    //   //window.fbq('track', 'Subscribe')
    // }
 
    
    setTimeout(() => {
      this.fireAPI();
    }, 5000);

  }

  fireAPI = async () => {
    localStorage.setItem('routing' , '')
     window.location.href = "/";
  
  }
  render() {

    return (
      <div className="container-loadaing">
        <div className="loadingLayout">
        <div className="keep-waiting">
          <div>
            {urlksa ? 
             <div className="test">
             <img src="/assets/images/playit.png" width="40px" />
             <img src="https://plannedy-cornance.com/conversion.gif?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL" width="1" height="1"/>
           </div>
            
            : null}
          {/* <div className="test">
          <img src="/assets/images/playit.png" width="40px" />
        </div> */}
        <div className="sk-chase">
        
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        </div>
        <div style={{marginTop: '80px'}}>
          <h1><Trans>waitksa</Trans></h1>
          <p><Trans>transferksa</Trans></p>
          </div>
        </div>

       
        </div>

      </div>


    );
  }
}
export default withTranslation()(KsaThankYouPage);
