import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import bgSliders from "../../helpers/bgSliders";
import "./ForgetPasswordPage.scss";
import ForgetPasswordPageDisplay from "./ForgetPasswordPage.styled";
import { client } from './../../auth/Client';
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import { Link } from 'react-router-dom';
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";


var urlyMobile = window.location.href.includes("ymobile");
var urlkorek = window.location.href.includes("localhost");

var urlsabafon = window.location.href.includes("sabafon");

class ForgetPasswordPage extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      errors: false,
      pinCode: "",
      password: "",
      code: false,
      errorMsg: ""
    };
  }
  componentDidMount() {
    bgSliders.randomBg();
  }
  componentWillUnmount() {
    bgSliders.removeBg();
  }

  async forgetPassword(e) {
    e.preventDefault();
    const { phone } = this.state;
    if (!this.isValid(phone)) {
      this.setState({
        errors: true,
        errorMsg: this.props.t("Empty Phone")
      });
      return;
    }

    const sentPassword = await client.forgetPassword(phone);
    if (!sentPassword || sentPassword === 404) {
      return this.setState({
        errors: true,
        errorMsg: this.props.t("not_correct_phone")
      });
    }
    else {
      if (urlyMobile || urlsabafon || urlkorek) {
        this.setState({
          code: true,
        });

      }

      else {
        this.props.history.push("/login");
      }


    }
  }

  async changePassword(e)
  {
    e.preventDefault();
    var urlsabafon = window.location.href.includes("sabafon");
    var urlkorek = window.location.href.includes("korek");

    const { phone , password , pinCode} = this.state;

    try {
      let params = {
        msisdn : phone,
         pincode: pinCode,
         password : password
      }
      
      var urlrestPassword = "http://api.playit.mobi/api/v2/yemen-mobile/reset-password";
      if(urlsabafon){
        urlrestPassword = "http://api.playit.mobi/api/v2/sabafon/reset-password";
      }

      if(urlkorek){
        urlrestPassword = "http://www.api.playit.mobi/api/v2/korek-trendy/reset-password";
      }
     

      const res = await axios.get(urlrestPassword, { params });
     //console.log(res);

      // if (!res.data) return false;
      const data = res.data;
      if (data.status === true) {
        // this.props.history.push("/login");
        if (data.data.user_id) {
         //console.log('Success' , data.data.user_id)
          const hasAccess = this.confirmLogin(data.data.username ,data.data.password)
          if (hasAccess) {
                this.props.history.push(`/slogin/${res.data.data.user_id}`);

      }
     
        }
      

      }
      else{
         this.setState({
          errors: true,
          errorMsg: this.props.t(data.message)
        });

      }
  } catch (err) {
    this.setState({
      errors: true,
      errorMsg: this.props.t(err)
    });
  }
  }


  async  confirmLogin(username,password) {

    const hasAccess = await client.login(username,password);
    return hasAccess;
  }

  isValid(phone) {
    return phone ? true : false;
  }

  onInputChange(name, value) {
    this.setState({
      [name]: value,
      errors: false
    });
  }
  render() {
    const { errors, errorMsg } = this.state;
    const { t } = this.props;

    return (
      <ForgetPasswordPageDisplay className="formPage">
        <form onSubmit={e => this.forgetPassword(e)}>
          <div className="formsLayout">
            <div className="header">
              <Link to="/">
                <div className="logo" />
              </Link>
            </div>
            <div className="center">
              <div className="form">
                  {!this.state.code ?
                  

                   (
                urlkorek ? (
                  <div className="number-inputs">
                    <input
                      readOnly
                      value={"+964"}
                      className="countryCode input"
                    />
                    <div className="iconInput">
                      <i className="icon phone" />
                      <input
                        type="number"
                        className="input"
                        placeholder={t("enter_phone_number")}
                        onChange={e => this.onInputChange("phone", e.target.value)}
                      />
                    </div>
                  </div>
                ) : 
                
                (
                  <div className="iconInput">
                    <i className="icon phone" />
                    <input
                      type="number"
                      className="input"
                      placeholder={t("enter_phone_number")}
                      onChange={e => this.onInputChange("phone", e.target.value)}
                    />
                  </div>
                )
              ) 

                    
                    
                    
                    
                    
                    
                    
                    
                    :
                    
                    
                    
                    
                    
                    
                    <div>
                    <div className="iconInput">

                      <input
                        type="number"
                        className="input"
                        placeholder={t("verfication.verification_code")}
                        onChange={e => this.onInputChange("pinCode", e.target.value)}
                      />
                      </div>
                      <br></br>
                    
                      <div className="iconInput">
                      <i className="icon lock"/>

                      <input
                      
                        type="password"
                        className="input"
                        placeholder={t("profile.new")}
                        onChange={e =>
                          this.onInputChange("password", e.target.value)
                        }
                      />
                      </div>

                    </div>


                   



                  }

              </div>

              
            </div>
            <div className="footer">
              {!this.state.code ?
                <>
                  <p className="txt">
                    <Trans>resetPassward.reset_message</Trans>
                  </p>
                  <div className="normBtn">
                    <button className="btn" type="submit">
                      <Trans>resetPassward.reset</Trans>
                    </button>
                  </div>
                </>
                :
                <div className="normBtn">
                  <button className="btn" type="button" onClick={e => this.changePassword(e)}>
                    <Trans>Verify_code</Trans>
                  </button>
                </div>
              }
            </div>
          </div>
        </form>
        {errors ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
      </ForgetPasswordPageDisplay>
    );
  }
}
export default withTranslation()(ForgetPasswordPage);
